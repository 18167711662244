import { Tab } from "@/components/layouts/types/tab";

export type Range = [number, number];

export const urlQueryDeploymentTabName = "deployment_details_tab";

export const TABS = {
    GENERAL: { title: "General" } as Tab,
    PERFORMANCE: { title: "Performance" } as Tab,
    POD: { title: "Pod" } as Tab,
    GRAPHS: { title: "Graphs" } as Tab,
    GPU: { title: "GPUs" } as Tab,
    LOGS: { title: "Logs" } as Tab,
    EVENTS: { title: "Status History" } as Tab,
};
