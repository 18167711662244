import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RaTableCell = _resolveComponent("RaTableCell")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.tableWrapper)
  }, [
    _createVNode(_component_Table, { flex: false }, {
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: col.key
          }, [
            _createElementVNode("td", null, _toDisplayString(col.label), 1),
            _createElementVNode("td", null, [
              (col.component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(col.component), _normalizeProps(_mergeProps({ key: 0 }, _ctx.getCellProps(col, _ctx.model))), null, 16))
                : (_openBlock(), _createBlock(_component_RaTableCell, {
                    key: 1,
                    value: _ctx.model[col.dataKey],
                    row: _ctx.model,
                    column: col
                  }, null, 8, ["value", "row", "column"]))
            ])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ], 2))
}