
import { DataGridColumn } from "@/core-ui/types/column";
import { Table } from "@/core-ui/data-grid/components";
import RaTableCell from "@/core-ui/data-grid/components/RaTableCell.vue";
import { defineComponent, PropType } from "vue";
import { getCellProps } from "@/core-ui/data-grid/utils";

export default defineComponent({
    components: { RaTableCell, Table },
    props: {
        model: {},
        columns: Array as PropType<Array<DataGridColumn>>,
    },
    setup: () => ({
        getCellProps,
    }),
});
