
import IFrame from "@/components/iframe.vue";
import { defineComponent, ref, watch } from "vue";
import podsService from "@/services/pods-service";
import { Pod } from "@/models/pods.model";

export default defineComponent({
    components: { IFrame },
    props: { model: Object },
    setup: (props: any) => {
        let iframeQ = ref<object>({});
        watch(
            () => props?.model,
            () => {
                podsService.get("", props?.model?.id).then(podServiceRes => {
                    iframeQ.value =  {
                        "var-deployment": props?.model?.name,
                        "var-pod": podServiceRes?.map((c: Pod) => c.name).join("|"),
                        "var-namespace": props?.model?.namespace,
                    }
                });
            },
        );
        return {
            iframeQ,
        };
    },
});
