
import IFrame from "@/components/iframe.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: { IFrame },
    props: { model: Object },
    setup: (props: any) => {
        return {
            get query(): any {
                let lastIndexOf = props?.model?.name.lastIndexOf('-deployment');
                const revision = lastIndexOf == -1 ? props?.model?.name : props?.model?.name.slice(0, lastIndexOf);
                return {
                    "var-revision": revision
                };
            },
        };
    },
});
