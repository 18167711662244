
import { defineComponent, ref, watch } from "vue";
import TextButton from "@/core-ui/TextButton.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import PodService from "@/services/pods-service";
import { arrayEquals } from "@/utils/ArrayUtil";
import LogConsole from "@/cluster-ui/components/jobs/LogConsoleV2.vue";

export default defineComponent({
    components: { LogConsole, TextButton, IconButton },
    props: {
        deployment: { type: Object, required: true }
    },
    setup(props) {
        const storageTail = localStorage.getItem("deploymentLogsTail");
        const tail = ref(storageTail ? Number(storageTail) : 100);

        return {
            tail,
            open: ref(false),
            podName: ref(""),
            clusterApi,
            props
        };
    },
    data() {
        let pods : string[] = [];
        return {
            pods: pods
        }
    },
    mounted() {
        //Watch for changes in props - a different deployment has different pods
        watch(this.props, () => {
            this.updatePods();
        });
        this.updatePods();
        //The pods of a deployment might change as time progresses.
        // Because watch(this.props) is triggered periodically, we don't need to add a setInterval call on updatePods.
    },
    methods: {
        async updatePods() {
            //Calc pods for current deployment
            const updatedPods: string[] = (await PodService.get("", this.deployment.id)).map((pod) => pod.name);

            if (arrayEquals(this.pods, updatedPods)) {  //Update the pods list only if it has changed
                return;
            }

            this.pods.splice(0,this.pods.length); //clear old pods
            for (let updatedPodName of updatedPods) {
                this.pods.push(updatedPodName);
            }

            //Update pod for logView
            //If we can't find any pods matching the deployment, no logs should be printed to the log console
            if (this.pods.length <= 0) {
                this.podName = "";
            }
            //Set new pod only if it doesn't have one currently or the current pod isn't part of the current deployment
            else if (this.podName == undefined || this.podName == "" || !this.pods.includes(this.podName)) {
                this.podName = this.pods[0];
            }
        },
        changeTail() {
            localStorage.setItem("deploymentLogsTail", this.tail.toString());
        },
    },
});
