import apiClient from './api-client';
import { Deployment } from '@/types/deployments';
import clustersStore from '@/stores/clusters-store';
import podsService from '@/services/pods-service';
import { Pod } from '@/models/pods.model';
import { filterAPIToUrlQuery, filterQueryToUrlQuery } from '@/core-ui/data-grid/utils';
import { FilterAPI } from '@/core-ui/data-grid/compositions';
import { ListData } from '@/core-ui/data-grid/types';

class DeploymentsService {
    constructor(private props: { clusterUuid: string }) {}

    async getDeployments(
        clusterUUID: string = this.props.clusterUuid,
        filter?: FilterAPI,
    ): Promise<ListData<Deployment>> {
        return this.getDeploymentsByType(clusterUUID, 'current', filter);
    }

    async getDeletedDeployments(
        clusterUUID: string = this.props.clusterUuid,
        filter?: FilterAPI,
    ): Promise<ListData<Deployment>> {
        return this.getDeploymentsByType(clusterUUID, 'deleted', filter);
    }

    async getDeploymentNodes(deploymentId: string, clusterUUID: string = this.props.clusterUuid): Promise<string[]> {
        const pods: Pod[] = await podsService.get("", deploymentId, clusterUUID);
        return [...new Set(pods.map((p) => p.nodeId))];
    }
    
    async getDeploymentsCount(clusterUUID: string, type: string, filter?: string): Promise<number> {
        const params = [];

        if (filter) {
            params.push(`filter=${filter}`);
        }

        const url = DeploymentsService.getCountUrlByType(clusterUUID, type, params.join('&'));

        try {
            const res = await apiClient.asyncRequestWithResponse(url, 'GET');
            return res.count || 0;
        } catch (e) {
            console.log(`failed to get deployments count for type ${type}: `, e);
            return 0;
        }
    }

    private async getDeploymentsByType(
        clusterUUID: string,
        type: string,
        filter?: FilterAPI,
    ): Promise<ListData<Deployment>> {
        const params = filter ? filterAPIToUrlQuery(filter) : '';
        const url = DeploymentsService.getDataUrlByType(clusterUUID, type, params);
        let [data, length] = await Promise.all([
            apiClient.asyncRequestWithResponse(url, 'GET'),
            this.getDeploymentsCount(clusterUUID, type, filterQueryToUrlQuery(filter?.query || '')),
        ]);

        data = data.map((d: Deployment) => {
            // backward compatibility to support case we don't have project name stored
            if (d.project === undefined || d.project.length == 0) {
                d.project = namespaceToRunaiProject(d.namespace);
            }
            return d;
        });

        return { data, length };
    }

    private static getDataUrlByType(clusterUUID: string, type: string, queryParams: string): string {
        if (type == 'deleted') {
            return `${DeploymentsService.baseDeploymentsUrl(clusterUUID)}/deleted/${queryParams}`;
        }
        return `${DeploymentsService.baseDeploymentsUrl(clusterUUID)}/${queryParams}`;
    }

    private static getCountUrlByType(clusterUUID: string, type: string, queryParams: string): string {
        if (type == 'deleted') {
            return `${DeploymentsService.baseDeploymentsUrl(clusterUUID)}/deleted/count?${queryParams}`;
        }
        return `${DeploymentsService.baseDeploymentsUrl(clusterUUID)}/count?${queryParams}`;
    }

    private static baseDeploymentsUrl(clusterUUID: string) {
        return `v1/k8s/clusters/${clusterUUID}/deployments`;
    }
}

function namespaceToRunaiProject(value: string): string {
    const PREFIX = 'runai-';
    if (value.startsWith(PREFIX)) {
        value = value.slice(PREFIX.length);
    }
    return value;
}

const deploymentsService = new DeploymentsService({
    get clusterUuid() {
        return clustersStore.state.activeClusterUUID;
    },
});
export default deploymentsService;
