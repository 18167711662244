import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db6e2ec8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["content"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusLabel = _resolveComponent("StatusLabel")!
  const _component_RaTabs = _resolveComponent("RaTabs")!
  const _component_GeneralTab = _resolveComponent("GeneralTab")!
  const _component_PodsTab = _resolveComponent("PodsTab")!
  const _component_GraphsTab = _resolveComponent("GraphsTab")!
  const _component_GpusTab = _resolveComponent("GpusTab")!
  const _component_PerformanceTab = _resolveComponent("PerformanceTab")!
  const _component_K8sDeploymentEventsTab = _resolveComponent("K8sDeploymentEventsTab")!
  const _component_LogsTab = _resolveComponent("LogsTab")!
  const _component_DetailsPanel = _resolveComponent("DetailsPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_DetailsPanel, {
    open: _ctx.hasDeployment,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.deployment)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.row, _ctx.$style.header])
            }, [
              _withDirectives((_openBlock(), _createElementBlock("label", {
                content: _ctx.deployment.name,
                class: _normalizeClass(_ctx.$style.title)
              }, [
                _createTextVNode(_toDisplayString(_ctx.deployment.name), 1)
              ], 10, _hoisted_1)), [
                [_directive_tippy]
              ]),
              (!_ctx.isHistory)
                ? (_openBlock(), _createBlock(_component_StatusLabel, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.status),
                    value: _ctx.deployment.status
                  }, null, 8, ["class", "value"]))
                : _createCommentVNode("", true)
            ], 2),
            _createVNode(_component_RaTabs, {
              tabs: _ctx.tabs,
              "current-tab": _ctx.currentTab,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTab = $event))
            }, null, 8, ["tabs", "current-tab"]),
            (_ctx.currentTab.title === _ctx.TABS.GENERAL.title)
              ? (_openBlock(), _createBlock(_component_GeneralTab, {
                  key: 0,
                  model: _ctx.deployment,
                  columns: _ctx.generalColumns
                }, null, 8, ["model", "columns"]))
              : (_ctx.currentTab.title === _ctx.TABS.POD.title)
                ? (_openBlock(), _createBlock(_component_PodsTab, {
                    key: 1,
                    "deployment-id": _ctx.deployment.id
                  }, null, 8, ["deployment-id"]))
                : _createCommentVNode("", true),
            (_ctx.currentTab.title === _ctx.TABS.GRAPHS.title)
              ? (_openBlock(), _createBlock(_component_GraphsTab, {
                  key: 2,
                  model: _ctx.deployment
                }, null, 8, ["model"]))
              : (_ctx.currentTab.title === _ctx.TABS.GPU.title)
                ? (_openBlock(), _createBlock(_component_GpusTab, {
                    key: 3,
                    deployment: _ctx.deployment
                  }, null, 8, ["deployment"]))
                : _createCommentVNode("", true),
            (_ctx.currentTab.title === _ctx.TABS.PERFORMANCE.title)
              ? (_openBlock(), _createBlock(_component_PerformanceTab, {
                  key: 4,
                  model: _ctx.deployment,
                  columns: _ctx.generalColumns
                }, null, 8, ["model", "columns"]))
              : (_ctx.currentTab.title === 'Status History')
                ? (_openBlock(), _createBlock(_component_K8sDeploymentEventsTab, {
                    key: 5,
                    "deployment-id": _ctx.deployment.id
                  }, null, 8, ["deployment-id"]))
                : (_ctx.currentTab.title === 'Logs')
                  ? (_openBlock(), _createBlock(_component_LogsTab, {
                      key: 6,
                      deployment: _ctx.deployment
                    }, null, 8, ["deployment"]))
                  : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}