import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusLabel = _resolveComponent("StatusLabel")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, { onPopped: _ctx.onPopped }, {
    reference: _withCtx(() => [
      _createVNode(_component_StatusLabel, { value: _ctx.value }, null, 8, ["value"])
    ]),
    default: _withCtx(() => [
      (_ctx.isData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (condition) => {
              return (_openBlock(), _createElementBlock("li", {
                key: condition.type
              }, _toDisplayString(condition.message), 1))
            }), 128))
          ]))
        : (!_ctx.isData)
          ? (_openBlock(), _createBlock(_component_Loading, {
              key: 1,
              style: {"border-radius":"4px","overflow":"hidden"}
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, "Not Found"))
    ]),
    _: 1
  }, 8, ["onPopped"]))
}