import {AddDefaultSort, createModelMeta, DataGridModelProps, Filter} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { ClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import { createCompLinkForProject, useProjectLazyCache } from "@/models/projects.model";
import deploymentsService from "@/services/deployments-service";
import { Deployment } from "@/types/deployments";
import { dateFormat, numeric } from "@/core-ui/helpers/data-formats";
import MemoryLabel from "@/core-ui/MemoryLabel.vue";
import StatusLabelWithConditionTooltip from "@/components/jobs/StatusLabelWithConditionTooltip.vue";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import { toFilterAPI } from "@/core-ui/data-grid/utils";
import {computed} from "vue";

export type DeploymentDisplay = {
    deploymentId: string;
    deploymentName: string;
    status: string;
    project: string;
    created: number;
};

export const createColumns = ({ projectCache }: { projectCache: any }): DataGridColumn[] => [
    {
        key: "deploymentId",
        label: "Deployment ID",
        dataKey: "id",
        sortable: true,
        searchable: true,
        primary: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "revisionUuid",
        label: "Revision ID",
        dataKey: "revisionUuid",
        sortable: true,
        searchable: true,
        primary: true,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    {
        key: "deploymentName",
        label: "Deployment Name",
        dataKey: "name",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
            },
        },
    },
    {
        key: "status",
        label: "Status",
        dataKey: "status",
        reverseKey: "status",
        sortable: true,
        searchable: true,
        display: {
            component: StatusLabelWithConditionTooltip,
            table: {
                width: 150,
            },
        },
    },
    {
        key: "namespace",
        label: "Project",
        dataKey: "project",
        searchable: true,
        sortable: true,
        display: {
            ...createCompLinkForProject(projectCache, false),
            table: {
                hidden: true,
                width: 180,
            },
        },
    },
    {
        key: "nodePool",
        label: "Node Pool",
        dataKey: "nodePool",
        sortable: true,
        searchable: true,
        display: {
            table: {
                hidden: true,
                width: 180,
            },
        },
    },
    {
        key: "creation",
        label: "Creation Time",
        dataKey: "createdAt",
        sortable: true,
        dataTransform: dateFormat,
        display: {
            table: {
                width: 160,
            },
        },
    },
    {
        key: "containers",
        label: "Image",
        dataKey: "template",
        sortable: true,
        dataTransform: (template: any) =>
            template?.containers
                ?.filter((c: any) => !isSideCarContainer(c.name))
                .map((c: any) => c.image)
                .join(", "),
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "url",
        label: "URL",
        dataKey: "url",
        searchable: true,
        sortable: true,
        display: {
            table: {
                hidden: true,
                width: 180,
            },
        },
    },
    {
        key: "username",
        label: "Username",
        dataKey: "username",
        searchable: true,
        sortable: true,
        display: {
            table: {
                hidden: true,
                width: 180,
            },
        },
    },
    {
        key: "replicas",
        label: "Replicas",
        dataKey: "replicas",
        sortable: true,
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "gpuRequestPerReplica",
        label: "Requested GPUs per Replica",
        dataKey: "template",
        sortable: true,
        dataTransform: (template: any) => template?.resourceRequest?.["nvidia.com/gpu"] || 0,
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "cpuUsage",
        label: "Used CPU",
        dataKey: "cpuUsage",
        dataTransform: numeric,
        sortable: true,
        display: {
            table: {
                width: 170,
            },
        },
    },
    {
        key: "memoryUsage",
        label: "Used Memory",
        dataKey: "memoryUsage",
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            table: {
                width: 150,
            },
        },
    },
    {
        key: "gpuAllocation",
        label: "Allocated GPUs",
        dataKey: "gpuAllocation",
        sortable: true,
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "gpuMemoryAllocation",
        label: "Allocated GPU Memory",
        dataKey: "gpuMemoryAllocation",
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            table: {
                width: 200,
            },
        },
    },
];

function isSideCarContainer(containerName: string): boolean {
    const regex: string = settingStore.state?.kv[SettingKeys.DeploymentsSidecarContainerRegex] || "queue-proxy";
    const matchResult = containerName.match(regex);
    return !!matchResult;
}

export type ModalType = Deployment;
const type = "deployment";
export const meta = createModelMeta<ModalType>({
    icon: () => `raicon-${type}`,
    type,
    display: (item) => item.name,
});

export const createModelProps = (
    props: ClusterUUIDKey,
    filters: { readonly mode: string },
): DataGridModelProps<ModalType> => {
    const projectCache: any = useProjectLazyCache();
    const columns = createColumns({ projectCache });
    const computedColumns = computed(() => AddDefaultSort(columns, type, 'status'));

    return {
        get tableName() {
            return type;
        },
        meta,
        columns: computedColumns.value,
        get dataKey() {
            return `${props.key}-${props.dataKey}`;
        },
        syncServer: true,
        fetchInterval: 10000,
        fetch: (async (filter: Filter) => {
            const filterApi = toFilterAPI(computedColumns.value, filter);
            if (filters.mode == "History") {
                return await deploymentsService.getDeletedDeployments(props.clusterUUID, filterApi);
            } else {
                return await deploymentsService.getDeployments(props.clusterUUID, filterApi);
            }
        }) as any,
    };
};
