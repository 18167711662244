
import PerformanceTab from "../deployments/PerformanceTab.vue";
import GeneralTab from "../deployments/GeneralTab.vue";
import { DataGridColumn } from "@/core-ui/types/column";
import { defineComponent, PropType, ref, watch } from "vue";
import { routerService } from "@/core-ui/data-grid/services";
import { TABS, urlQueryDeploymentTabName } from "../deployments/utils";
import JobGraphTab from "@/components/jobs/GraphsTab.vue";
import DetailsPanel from "@/core-ui/data-grid/components/DetailsPanel.vue";
import RaTabs from "@/components/layouts/RaTabs.vue";
import StatusLabel from "@/core-ui/StatusLabel.vue";
import { Tab } from "@/components/layouts/types/tab";
import PodsTab from "@/components/pods/PodsTab.vue";
import K8sDeploymentEventsTab from "@/components/k8s-events/k8sDeploymentEventsTab.vue";
import LogsTab from "@/components/deployments/LogsTab.vue";
import GraphsTab from "@/components/deployments/GraphsTab.vue";
import GpusTab from "@/components/gpus/GpusTab.vue";
import settingStore, { SettingKeys } from "@/stores/setting-store";

export default defineComponent({
    components: {
        GpusTab,
        GraphsTab,
        LogsTab,
        K8sDeploymentEventsTab,
        DetailsPanel,
        RaTabs,
        GeneralTab,
        PodsTab,
        PerformanceTab,
        StatusLabel,
    },
    emits: ["close"],
    props: {
        deployment: Object,
        columns: Array as PropType<Array<DataGridColumn>>,
        isHistory: {type: Boolean, default: false}
    },
    methods: {
        webkitTransitionEndCallback(){
            if (this.$el.clientWidth) {
                this.transitionEnded = true
            }
        },
    },
    setup(props: any) {
        const currentIndexTab = ref(0);
        const transitionEnded = ref(false);

        watch(
            () => props.deployment,
            () => {
                if (!props.deployment) {
                    transitionEnded.value = false;
                }
            },
        );
        const self = {
            currentIndexTab,
            transitionEnded,

            TABS,

            get tabs(): Array<Tab> {
                let tabs: Tab[] = [TABS.GENERAL, TABS.POD];
                let performanceTab: Tab = TABS.PERFORMANCE;
                performanceTab.disabled = !props.deployment!.revisionUuid || props.deployment.deletedAt;
                if (!performanceTab.disabled) {
                    tabs.push(performanceTab);
                }

                let graphTab: any;
                if (settingStore.state?.kv[SettingKeys.UseOldInference]) {
                    graphTab = JobGraphTab;
                } else
                    graphTab = TABS.GRAPHS;

                graphTab.disabled = props.deployment.deletedAt;
                if (!graphTab.disabled) {
                    tabs.push(graphTab);
                }
                let gpuTab: Tab = TABS.GPU;
                gpuTab.disabled = props.deployment.deletedAt;
                if (!gpuTab.disabled) {
                    tabs.push(gpuTab);
                }
                tabs.push(TABS.EVENTS);
                tabs.push(TABS.LOGS);
                return tabs;
            },

            get currentTab(): Tab {
                const title = routerService.getStr(urlQueryDeploymentTabName, TABS.GENERAL.title);
                const tab = self.tabs.find((t: Tab) => t.title == title);
                return tab ? tab : TABS.GENERAL;
            },
            set currentTab(tab: Tab) {
                routerService.setStr(urlQueryDeploymentTabName, tab.title);
            },

            get hasDeployment() {
                return !!props.deployment;
            },

            get generalColumns() {
                return props.columns!.filter((col: any) => col.dataKey !== "status" && col.dataKey !== "name");
            },
        };
        return self;
    },

    mounted() {
        this.$parent!.$el.addEventListener("webkitTransitionEnd", this.webkitTransitionEndCallback);
    },

    unmounted() {
        this.$parent!.$el.removeEventListener("webkitTransitionEnd", this.webkitTransitionEndCallback);
    }
});
