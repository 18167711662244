
// components
import StatusLabel from "@/core-ui/StatusLabel.vue";
import Popper from "@/core-ui/Popper.vue";
import Loading from "@/core-ui/Loading.vue";

import { defineComponent, PropType } from "@vue/runtime-core";
import { useCacheFetch } from "@/core-ui/data-grid/compositions";
import { Deployment } from "@/types/deployments";

export default defineComponent({
    components: { StatusLabel, Popper, Loading },
    props: {
        row: Object as PropType<Deployment>,
        value: {},
        ctx: Object,
    },
    setup(props) {
        const cache = useCacheFetch({
            get key() {
                return props?.row?.id || "id";
            },
            fetch: () => {
                return props?.row?.conditions;
            },
        });
        const self = {
            get isData() {
                return !!cache.data;
            },
            get data() {
                return cache.data;
            },

            open() {
                const id = props.row?.id;
                if (!id || !props.ctx?.selector) {
                    return;
                }
                if (!props.ctx?.selector?.isSelected(props.row!.id)) {
                    props.ctx!.selector.toggle(id);
                }
            },

            onPopped() {
                if (cache.state == "idle") {
                    cache.fetch();
                }
            },
        };
        return self;
    },
});
